import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/app/[locale]/(public)/signin/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/data-dashboard/app/[locale]/(public)/signin/NewsButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/data-dashboard/app/[locale]/(public)/signin/styles.module.css");
;
import(/* webpackMode: "eager" */ "/app/apps/data-dashboard/assets/images/bg-minimal.jpg");
;
import(/* webpackMode: "eager" */ "/app/apps/data-dashboard/assets/images/mercedes-benz-data-dashboard-logo-zweizeilig.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.11.1_next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1._fhh3b6tuc7vp7gilj4urs5iya4/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.11.1_next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1._fhh3b6tuc7vp7gilj4urs5iya4/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.20_@babel+core@7.26.7_@opentelemetry+api@1.9.0_@playwright+test@1.50.1_react-dom@18_nj2u5fwuic6phqql7bw45ijx24/node_modules/next/dist/client/image-component.js");
